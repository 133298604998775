import { useStaticQuery, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Slanted from "../components/layout/slanted"
import SEO from "../components/SEO"
import { useQueryParam } from "gatsby-query-params"
import Spinner from "../../assets/images/Spinner_font_awesome.svg"

const Confirmation = () => {
  const {
    site: {
      siteMetadata: { backendUrl },
    },
  } = useStaticQuery(graphql`
    query ConfirmationQuery {
      site {
        siteMetadata {
          backendUrl
        }
      }
    }
  `)
  const orderId = useQueryParam("orderId", null)
  const [status, setStatus] = useState(null)
  useEffect(() => {
    if (orderId) {
      const url = backendUrl + "/orders/" + orderId
      fetch(url, {
        method: "PUT",
      }).then(result => {
        setStatus(result.status)
      })
    }
    return () => {}
  }, [orderId, backendUrl])

  let content = <></>

  switch (status) {
    case null:
      content = (
        <>
          <h2>Laddar ...</h2>
          <Spinner className="w-32 animate-spin" />
        </>
      )
      break

    case 200:
      content = (
        <>
          <h2>Tack för din beställning</h2>
          <p>Vi kommer att skicka valsedlar till dig i god tid innan valet!</p>
        </>
      )
      break

    default:
      content = (
        <>
          <h2>Något gick fel :(</h2>
        </>
      )
      break
  }

  return (
    <Layout>
      <SEO title="Konfirmera din e-post" />
      <Slanted>{content}</Slanted>
    </Layout>
  )
}

export default Confirmation
